import React from 'react'

import { colors } from '../utils/theme'

export const FreeReportDetails = () => (
  <div>
    <div css={{ padding: '40px', backgroundColor: colors.lightGrey }}>
      <h2 id="why-read-this-report" css={{ textAlign: 'center' }}>
        Why Read This Report
      </h2>
      <p
        css={{
          textAlign: 'center',
          maxWidth: '800px',
          margin: '0 auto',
          padding: '10px',
        }}
      >
        Are you a Mayo employee and interested in braces or Invisalign for
        yourself and your family, but need more information about your
        orthodontic benefit? You are not alone. In fact, we get dozens of
        questions about this topic from countless Mayo employees and their
        families at our office every single day. Questions like the following
        are more common than you think –{' '}
        <strong>
          Can I use my benefit at any orthodontic office, or do I have to go to
          Mayo? Who in my family qualifies for the benefit? How much does it
          cover? What is the most cost-effective option to use my Mayo Benefit?
          When is the ideal time to use my Mayo orthodontic benefit?
        </strong>
        And many more! If you want to know how to maximize your Mayo orthodontic
        benefit for yourself and your family, then I STRONGLY suggest signing up
        for the following FREE report in order to be fully informed about your
        Mayo Orthodontic benefit.
      </p>
    </div>
    <div
      css={{
        padding: '40px',
        textAlign: 'center',
        color: colors.brightBlue,
        fontSize: '18px',
      }}
    >
      <h2>In This Free Report, You Will Learn The Following:</h2>
      <p>WHO Qualifies for the Mayo Orthodontic Benefit</p>
      <p>WHAT is Included in Your Mayo Orthodontic Benefit</p>
      <p>WHERE Can Your Orthodontic Benefit be Used</p>
      <p>WHEN is the ideal time to use your Mayo Orthodontic Benefit</p>
      <p>HOW to Choose the “Perfect Fit” for Your Child’s Braces</p>
    </div>
  </div>
)
