import React from 'react'

// App components
import ContentAndSignupForm from '../../components/ContentAndSignupForm'
import MainHelmet from '../../components/MainHelmet'
import { FreeReportDetails } from '../../components/FreeReportDetails'
// App content
import consultingStaff from '../../assets/mayo-landing/consulting-staff.png'

class Page extends React.Component {
  render() {
    const content = (
      <div>
        <p css={{ marginBottom: 0 }}>
          Are you a Mayo Physician, Consulting or Voting Administrative Staff?
        </p>
        <h2>Your Entire Family is Eligible for 100% Orthodontic Coverage</h2>
        <p>
          If you are a Mayo Physician, Consulting or Voting Administrative
          Staff, then you and your entire family are eligible for 100%
          orthodontic coverage at Collins Orthodontics. Essentially, Mayo Clinic
          will be covering the entire cost of treatment for you and your entire
          family. However, we do have some suggestions for you to make sure you
          end up paying nothing out-of-pocket, and we’ve put these suggestions
          in this free report.
        </p>
      </div>
    )
    return (
      <div>
        <MainHelmet
          description={`If you are a Mayo Physician, Consulting or Voting Administrative Staff, then you and your entire family are eligible for 100% orthodontic coverage at Collins Orthodontics. Essentially, Mayo Clinic will be covering the entire cost of treatment for you and your entire family. However, we do have some suggestions for you to make sure you end up paying nothing out-of-pocket, and we’ve put these suggestions in this free report.`}
          title={`Your entire family may qualify for 100% Orthodontic Coverage!`}
        />
        <ContentAndSignupForm
          thumbnail={consultingStaff}
          mainTitle={`Top 5 Things You Must Know About Your Orthodontic Benefit`}
          title={
            'Mayo Physicians, Consulting Staff and Voting Administrative Staff Edition'
          }
          content={content}
          postContent={<FreeReportDetails />}
          configKey="mayoPhysicians"
          captchaId="recaptcha_8617f980876b3fc06d8ec9d4f7519ded"
        />
      </div>
    )
  }
}

export default Page
